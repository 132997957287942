<template>
  <div class="container">
    <Header class="header" />
    <main class="leader">
      <div class="about-us">
        <img class="title" src="../../assets/img/mobile/2023/about.png" alt="">
        <img class="ball3" src="../../assets/img/mobile/2023/ball3.png" alt="">
        <div class="content">
          <span>UPUPOO动态壁纸桌面（啊噗啊噗）</span>
          <span>是一款多功能桌面美化软件，自2017年推出至今深受</span>
          <span>用户喜爱。覆盖平台有电脑手机/平板（Android）</span>
          <span>其中手机版包含海量高清动态壁纸，一键即可快速应用。</span>
          <span>同时手机端适配于安卓平板使用，账号支持多端登陆。</span>
        </div>
      </div>
      <div class="account">
        <img class="title" src="../../assets/img/mobile/2023/account.png" alt="">
        <div class="qr">
          <div class="qr-code">
            <img src="../../assets/img/mobile/2023/dy.png" alt="">
            <span>抖音</span>
          </div>
          <div class="qr-code">
            <img src="../../assets/img/mobile/2023/wechat.png" alt="">
            <span>微信公众号</span>
          </div>
        </div>
        <a href="https://weibo.com/upupoo/"><span>微博：https://weibo.com/upupoo/</span></a>
        <a href="https://space.bilibili.com/452371941"><span>b站：https://space.bilibili.com/452371941</span></a>
        <a href="https://www.zhihu.com/org/upupoo-33"><span>知乎：https://www.zhihu.com/org/upupoo-33</span></a>
      </div>
      <div class="call">
        <img class="title" src="../../assets/img/mobile/2023/call.png" alt="">
        <img class="ball3" src="../../assets/img/mobile/2023/ball3.png" alt="">
        <div class="cooperation">
          <span>联系人：周女士</span>
          <span>合作邮箱：zhouqian@xiaominet.com</span>
          <span>商务QQ：375186779（申请时请备注合作类型）</span>
        </div>
        <div class="customer-service">
          <span>问题答疑QQ群：513327434</span>
          <span>客服邮箱：upupoo@upupoo.com</span>
          <span>工作时间：9:30-18:30</span>
        </div>
      </div>
      <div class="address">
        <img class="title" src="../../assets/img/mobile/2023/address.png" alt="">
        <div class="uphome">
          <span>地址：武汉小咪网络科技有限公司位于武汉市东湖高新</span>
          <span>区光谷大道61号光谷智慧园28栋</span>
        </div>
      </div>
    </main>

    <Downmost class="downmost" :type="go"></Downmost>
  </div>
</template>

<script>
import { init } from './utils';
import Header from './header.vue'
import Downmost from './downmost.vue'
export default {
  components: { Header, Downmost },
  data() {
    return {
      go: {
        name: '返回首页',
        link: './index2'
      },
    }
  },
  mounted() {
    init();
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  overflow-x: hidden;

  .header {
    position: fixed;
    top: 0;
    left: 0;
  }

  .leader {
    position: relative;
    left: 5.07%;
    margin-right: 4.13%;

    .about-us {
      position: relative;
      margin-top: 103px;

      .title {
        width: 42.53%;
        min-width: 161px;
        max-width: 241px;
      }

      .ball3 {
        position: absolute;
        left: 74.93%;
        top: 100px;
        width: 25.07%;
      }

      .content {
        margin-top: 33px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        line-height: 22px;
      }
    }

    .account {
      position: relative;
      margin-top: 70px;

      .title {
        width: 41.2%;
        min-width: 155px;
        max-width: 234px;
      }

      .qr {
        margin-top: 34px;
        display: flex;
        margin-bottom: 34px;

        img {
          // width: 32.8%;
          box-sizing: border-box;
          width: 123px;
          height: 123px;
          border: 2px solid rgba(164, 205, 255, 1);
          margin-bottom: 12px;
        }

        .qr-code {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 2.13%;

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
            line-height: 14px;
          }
        }
      }

      a {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        line-height: 14px;

        span {
          margin-top: 13px;
        }
      }
    }

    .call {
      position: relative;
      margin-top: 68px;

      .title {
        width: 52.93%;
        min-width: 200px;
        max-width: 300px;
      }

      .ball3 {
        position: absolute;
        left: 81.2%;
        top: 153px;
        width: 18.67%;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        line-height: 14px;
        margin-top: 13px;
      }

      .cooperation {
        margin-top: 34px;
      }

      .customer-service {
        margin-top: 32px;
      }
    }

    .address {
      position: relative;
      margin-top: 70px;

      .title {
        width: 31.87%;
        min-width: 119px;
        max-width: 180px;
      }

      .uphome {
        margin-top: 34px;
        margin-bottom: 52px;
      }
    }
  }
}
</style>